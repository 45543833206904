<template>
  <div class="home">
    <div class="logo">
      <img src="../assets/logo.png" class="img-fluid" alt="E-smartship 管理後台" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  mounted() {
    if (this.$store.getters.isLogined) {
      this.$router.replace({ name: 'dashboard' });
    } else {
      this.$router.replace({ name: 'login' });
    }
  },
};
</script>

<style lang="scss">
  .home {
    .logo {
      width: 50%;
      margin: 5% auto 0;
      text-align: center;
    }
  }
</style>
