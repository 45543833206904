<template>
  <div class="block-center mt-4 wd-xxl">
    <div class="card card-flat">
      <div class="card-header text-center bg-dark">
          <img class="block-center rounded" src="../assets/logo.png" alt="自郵行logo" height="70" />
      </div>
      <div class="card-body">
        <!--<p class="text-center py-2">SIGN IN TO CONTINUE.</p>-->
        <el-form
          ref="loginFrom"
          :model="loginForm"
          :rules="loginFormRules"
          label-width="4rem"
          class="mb-3"
          @submit.native.prevent
        >
          <div class="form-group">
            <el-form-item label="賬號" prop="account">
              <el-input v-model="loginForm.account" placeholder="請輸入賬號" autofocus clearable @keyup.enter.native="summit" />
            </el-form-item>
          </div>
          <div class="form-group">
            <el-form-item label="密碼" prop="password">
              <el-input v-model="loginForm.password" placeholder="請輸入密碼" show-password @keyup.enter.native="summit" />
            </el-form-item>
          </div>
          <button type="button" class="btn btn-block btn-primary mt-3" @click="summit">Login</button>
        </el-form>
      </div>
    </div>
    <div class="p-3 text-center">
      <span class="mr-2">&copy;</span>
      <span>2020</span>
      <span class="mr-2">-</span>
      <span>ZTO</span>
      <br/>
      <span>Powered by Revs Limited</span>
    </div>
  </div>
</template>

<script>
import { getLoadingInstance } from '../helpers';

export default {
  data() {
    return {
      loginForm: {
        account: '',
        password: '',
      },
      loginFormRules: {
        account: { required: true, message: '請輸入賬號', trigger: 'blur' },
        password: { required: true, message: '請輸入密碼', trigger: 'blur' },
      },
    };
  },
  methods: {
    summit() {
      this.$refs.loginFrom.validate((valid) => {
        if (valid) {
          const param = {
            ...this.loginForm,
          };
          const loading = getLoadingInstance();
          this.$store.dispatch('authenticate', param)
            .then(() => {
              this.$router.push({ path: '/' });
            })
            .catch((e) => {
              if (e.response) {
                this.$notify({
                  title: this.$t('login.login_fail'),
                  message: e.response.data.message,
                  type: 'error',
                });
              }
            })
            .finally(() => {
              this.loginForm.account = '';
              this.loginForm.password = '';
              loading.close();
            });
        }
      });
    },
  },
};
</script>
